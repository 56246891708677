<template>
  <HeroBg
    heroTitle="Managing a company is hard,  <br class='hidden xl:inline-block' /> hiring talent shouldn’t be"
    heroDesc="Use our hands on on headhunting service to find top candidates regardless of experience level quicker and <br />
    cheaper."
    heroBtnText="Start Hiring"
    heroBtnLink="/contact"
    heroImg="hero6.png"
  />

  <Customers />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="mb-2 text-[32px] md:text-[40px] text-brand-black leading-[110%] font-semibold">Our Approach</h2>
        <p class="text-light-grey text-[18px] md:text-[30px] mb-[2.5rem]">What to expect when you work with us</p>

        <div class="md:grid md:grid-cols-2 gap-8 pb-5">
          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 1 of 4</span>
            <p class="text-[17px] leading-[120%] text-brand-black mb-6">
              Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to fill
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 2 of 4</span>
            <p class="text-[17px] leading-[120%] text-brand-black mb-6">
              Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to fill
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 3 of 4</span>
            <p class="text-[17px] leading-[120%] text-brand-black mb-6">
              Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to fill
            </p>
          </div>

          <div class="mb-4 md:mb-0 bg-light-yellow rounded-lg p-8">
            <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 4 of 4</span>
            <p class="text-[17px] leading-[120%] text-brand-black mb-6">
              Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to fill
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <WhoWeHelp />

  <Testimonial />

  <div class="flex justify-center bg-brand-black">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-white text-center">
        <h1 class="text-white text-[32px] md:text-[60px]">Ready to work together?</h1>

        <p class="text-[18px] md:text-[32px] leading-[110%] mb-[65px] text-white/50">We'll find you amazing remote employees for up to 80% less</p>
        <br />
        <div>
          <a href="/contact" class="rounded-3xl px-16 py-6 text-center bg-light-yellow text-brand-black text-[14px] md:text-[18px]"
            >Start Hiring now</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10 pb-5">
          <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
            <div class="w-[33px] h-[3px] bg-[#FDE442] mb-2"></div>

            <h1 class="mb-3 text-white leading-[130%] text-[21px]">Candidate Signup</h1>

            <p class="text-white mb-5 text-[16px] leading-[130%]">Create a profile and find your next opportunity</p>

            <div>
              <a href="" class="text-center text-[#FDE442]">Sign up to get hired</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
            <div class="w-[33px] h-[3px] bg-[#00F5D4] mb-2"></div>

            <h1 class="mb-3 text-white leading-[130%] text-[21px]">Employer Signup</h1>

            <p class="text-white mb-5 text-[16px] leading-[130%]">Sign up and find your next team member. You can use the self serve</p>

            <div>
              <a href="" class="text-center text-[#00F5D4]">Sign up hire</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
            <div class="w-[33px] h-[3px] bg-[#FE6D74] mb-2"></div>

            <h1 class="mb-3 text-white leading-[130%] text-[21px]">Start Hiring</h1>

            <p class="text-white mb-5 text-[16px] leading-[130%]">Reach out to our team to instantly connect you to top talents you want to hire.</p>

            <div>
              <a href="/contact" class="text-center text-[#FE6D74]">See how it works</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Testimonial from '@/components/partials/Testimonial.vue';
import WhoWeHelp from '@/components/partials/WhoWeHelp.vue';
import Customers from '@/components/partials/Customers.vue';
import HeroBg from '@/components/partials/HeroBg.vue';
import { useMeta } from '@/utils/meta';
useMeta();
</script>
